<template>
  <div>
    <!-- <el-autocomplete
      class="inline-input"
      v-model="selectVal"
      :fetch-suggestions="querySearch"
      :placeholder="placeholder"
      :disabled="disabled"
      @select="handleSelect"
      value-key="name"
      clearable
    ></el-autocomplete> -->
    <el-select
        v-model="selectVal"
        :motionType="motionType"
        :multiple="multiple"
        :placeholder="placeholder"
        clearable
        filterable
        style="width: 100%"
        @change="changeValue"
    >
      <el-option
          v-for="(item, index) in parkingList"
          :key="index"
          :label="item.name"
          :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import {getParkingLot} from "@/api/common";

export default {
  name: "suggest-parking",
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    placeholder: {
      type: String,
      default: "请选择车场",
    },
    parkingLotType: {
      type: String,
      default: "",
    },
    motionType: {
      type: Number,
      default: 0,
    },
    businessType:{
      type:String,
      default:""
    },
    disabled: Boolean,
    multiple: Boolean
  },
  data() {
    return {
      parkingList: [],
      selectVal: this.value,
      parkingName: "",
    };
  },
  watch: {
    value(newVal) {
      this.selectVal = !newVal || newVal === "" ? "" : newVal;
    },
    selectVal(newVal) {
      this.$emit("input", newVal);
    },
    motionType(newVal) {
      if (newVal) {
        this.getParkingLot(newVal);
      }
    },
    parkingLotType(newData){
      if (newData) {
        this.getParkingLot(newData)
      }
    },
  },
  methods: {
    async getParkingLot(parkingLotType,businessType) {
      const res = await getParkingLot({parkingLotType,businessType});
      if (res && res.code === 30 && res.result) {
        this.parkingList = res.returnObject.parkingLotList;
      }
    },

    changeValue(value) {
      this.parkingName = {};
      this.parkingList.map(item => {
        if (item.id === value) {
          this.parkingName = item
        }
      })
      this.$emit('parkingName', this.parkingName)
    }

  },
  mounted() {
    this.getParkingLot(this.parkingLotType,this.businessType);
  },
};
</script>

<style lang="less" scoped>
</style>
