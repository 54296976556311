/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。
 
// 密钥对生成 http://web.chacuo.net/netrsakeypair
 
// 公钥key
const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjR9M5tX2at1tXsyd4h1LQZSjiUTl57UZhBJ+Qolq/oo/bMVR+mBbS18aQrenAmV5UtcbLVRZb2vAfskIxzU6bYCnlaZClEhBvVeKh+JSq0GsTo16XsTXXzOH5BXXm7Q9o6oaImCOw1i4UQjGDGz8Ywj4RgnPP+P0SD51yWxIzRyCf9tivh9lVAEHWgC1hqU5PNPIoWUT2KLkqf+Mbe5sf3yt0qEZn4UpaM5V5vffd7Cvn6HSEU7bRTYGBhTw1RGmVP0bUOGmV4lIVUIUFZn+5kut12gX7GX6up6xPhSWSsIM2LL5EBwMPmbnOndqdb5xgHceo3rza8xnOwPk+XDMwwIDAQAB'
// 私钥key
const privateKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjR9M5tX2at1tXsyd4h1LQZSjiUTl57UZhBJ+Qolq/oo/bMVR+mBbS18aQrenAmV5UtcbLVRZb2vAfskIxzU6bYCnlaZClEhBvVeKh+JSq0GsTo16XsTXXzOH5BXXm7Q9o6oaImCOw1i4UQjGDGz8Ywj4RgnPP+P0SD51yWxIzRyCf9tivh9lVAEHWgC1hqU5PNPIoWUT2KLkqf+Mbe5sf3yt0qEZn4UpaM5V5vffd7Cvn6HSEU7bRTYGBhTw1RGmVP0bUOGmV4lIVUIUFZn+5kut12gX7GX6up6xPhSWSsIM2LL5EBwMPmbnOndqdb5xgHceo3rza8xnOwPk+XDMwwIDAQAB'
 
export default {
  /* JSEncrypt加密 */
  rsaPublicData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    var result = jsencrypt.encrypt(data); // 加密
    return result
    // console.log(result);
    
    // let hex = '';
    //   for (let i = 0; i < result.length; i++) {
    //     let data = result.charCodeAt(i).toString(16);
    //     console.log(data.length);
    //     const newData = data.length == 1 ? '0' + hex : hex;
    //     console.log(newData);

    //     hex += newData;
    //   }
    //   console.log(hex);
      
    // return hex;
  },
  // 加密并转为16进制
  rsaPublicData16(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    var result = jsencrypt.encrypt(data); // 加密
    // return result
    let hex = '';
      for (let i = 0; i < result.length; i++) {
        let data = result.charCodeAt(i).toString(16);
        data = data.length == 1 ? '0' + data : data;
        hex += data;
      }
    //   console.log(hex);
    return hex;
  },
  /* JSEncrypt解密 */
  rsaPrivateData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPrivateKey(privateKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data) 
    return result
  },
  /* 加密 */
  encrypt(data) {
    const PUBLIC_KEY = publicKey
    var encryptor = new Encrypt()
    encryptor.setPublicKey(PUBLIC_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    const result = encryptor.encrypt(data)
    return result
  },
  /* 解密 - PRIVATE_KEY - 验证 */
  decrypt(data) {
    const PRIVATE_KEY = privateKey
    var encryptor = new Encrypt()
    encryptor.setPrivateKey(PRIVATE_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = encryptor.decryptLong(data)
    return result
  }
}