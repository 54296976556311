import request, {exportService} from "@/utils/request";
// import { mockService } from "@/utils/request";
import {objectToQueryString} from "@/utils/utils";

// 查询收入流水
export function searchIncomeFlow(params) {
    return request.get(
        "/MoneyManagement/searchIncomeFlow?" + objectToQueryString(params)
    );
}

// 充值查询
export function searchRecharge(params) {
    return request.get(
        "/MoneyManagement/searchRecharge?" + objectToQueryString(params)
    );
}

// 交易查询
export function searchTrade(params) {
    return request.post(
        "/operation/tradeOrderList?" + objectToQueryString(params)
    );
}

// 获取支付日志
// export function searchPayLog(params) {
//   return request.get(
//     "/MoneyManagement/searchPayLog?" + objectToQueryString(params)
//   );
// }

//查询支付日志
export function searchpaylog(params) {
    return request.get("/pay/searchpaylog?" + objectToQueryString(params));
}

//查看支付日志详情
export function paylogdetail(id) {
    return request.post("/pay/paylogdetail?orderId=" + id);
}

/**
 * 导出收入流水
 * @param {*} params
 * @returns
 */
export function exportIncomeFlow(params) {
    return exportService.get('/MoneyManagement/searchIncomeFlow?' + objectToQueryString(params));
}

// 获取提现记录
export function getCashoutList(params) {
    return request.get('/wallet/getWithdrawRecord?' + objectToQueryString(params));
}

// 提现审批
export function doCashOutExamine(params) {
    return request.get('/wallet/withdrawVerify?' + objectToQueryString(params));
}

// 根据id获取提现详情
export function getWithdrawRecordById(withdrawRecordId) {
    return request.get('/wallet/getWithdrawRecordById?withdrawRecordId=' + withdrawRecordId);
}

// 获取历史申请记录
export function getHistoryRecords(settlementUserId) {
    return request.get('/wallet/applicationRecord?settlementUserId=' + settlementUserId);
}


// 查询收入流水
export function revenueFlow(params) {
    return request.post(
        "/wealthCenter/revenueFlow?" + objectToQueryString(params)
    );
}

// 查询充值
export function rechargeInquiry(params) {
    return request.post(
        "/wealthCenter/rechargeInquiry?" + objectToQueryString(params)
    );
}

// 查询对账管理
export function paymentLog(params) {
    return request.post(
        "/wealthCenter/checkBill?" + objectToQueryString(params)
    );
}

// 查询对账管理明细
export function paymentLogInfo(params) {
    return request.post(
        "/wealthCenter/checkBillInfo?" + objectToQueryString(params)
    );
}

// 查询坏账管理
export function badDebtManagement(params) {
    return request.post(
        "/wealthCenter/badDebtManagement?" + objectToQueryString(params)
    );
}

// 查询清分汇总
export function clearSummary(params) {
    return request.post(
        "/wealthCenter/clearSummary?" + objectToQueryString(params)
    );
}

// 查询清分明细
export function clearManagementInfo(params) {
    return request.post(
        "/wealthCenter/clearManagementInfo?" + objectToQueryString(params)
    );
}

// 查询清分管理
export function clearTaskManagement(params) {
    return request.post(
        "/wealthCenter/clearTaskManagement?" + objectToQueryString(params)
    );
}

// 新增清分管理
export function addClearTask(params) {
    return request.post(
        "/wealthCenter/addClearTask?" + objectToQueryString(params)
    );
}

// 清分禁用/启用
export function editClearTaskStatus(params) {
    return request.post(
        "/wealthCenter/editClearTaskStatus?" + objectToQueryString(params)
    );
}


// 查询清分详情
export function clearTaskInfo(params) {
    return request.post(
        "/wealthCenter/clearTaskInfo?" + objectToQueryString(params)
    );
}

// 查询审核列表
export function financialAudit(params) {
    return request.post(
        "/wealthCenter/financialAudit?" + objectToQueryString(params)
    );
}

// 财务审核明细
export function financialAuditInfo(params) {
    return request.post(
        "/wealthCenter/financialAuditInfo?" + objectToQueryString(params)
    );
}

// 确认打款
export function confirmPayment(params) {
    return request.post(
        "/wealthCenter/confirmPayment?" + objectToQueryString(params)
    );
}

// 电子发票列表
export function electronicInvoicePage(params) {
    return request.post(
        "/invoice/electronicInvoicePage?" + objectToQueryString(params)
    );
}

// 电子发票明细
export function electronicInvoiceInfo(params) {
    return request.post(
        "/invoice/electronicInvoiceInfo?" + objectToQueryString(params)
    );
}


// 预约列表
export function preParkingLotList(params) {
    return request.post(
        "/pre/preParkingLotList?" + objectToQueryString(params)
    );
}

// 预约编辑
export function preParkingLotOrderEdit(params) {
    return request.post(
        "/pre/preParkingLotOrderEdit", params
    );
}

// 预约明细
export function preParkingLotOrderDetail(params) {
    return request.post(
        "/pre/preParkingLotOrderDetail?" + objectToQueryString(params)
    );
}